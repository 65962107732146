import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "graphql/fragments";

export const FIND_ONE_USER_QUERY = gql`
  query findOneUser($input: FindOneUserInput!) {
    findOneUser(input: $input) {
      ok
      error
      user {
        ...UserParts
      }
    }
  }
  ${USER_FRAGMENT}
`;
