import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import { faAt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvatarTemplate from "assets/user-template.png";
import { Slideover } from "components";
import { UserParts } from "graphql/__generated-types__/UserParts";
import { useState } from "react";
import { formatPhone, formatTelegram } from "utils";
import { PersonDetails } from "./PersonDetails";
import moment from "moment";
import {useSearchParams} from "react-router-dom";
import {usefullIconsMap} from "../../../config/mappings";
import {Tooltip} from "react-tooltip";


interface IPersonCardProps {
  person: UserParts;
}

export const PersonCard: React.FC<IPersonCardProps> = ({ person }) => {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const now = moment();
  const holidayTo = moment(person.holidayTo).endOf('day');
  const birthdayDate = moment(person.birthdate, 'YYYY-MM-DD HH:mm:ss');
  const anniversaryDate = moment(person.employmentDate, 'YYYY-MM-DD HH:mm:ss');

  const handleClose = (value: boolean) => {
    setOpen(value);
    searchParams.delete('userid');
    setSearchParams(searchParams);
  }

  const handleOpen = () => {
    setOpen(true);
    searchParams.set('userid', `${person.id}`);
    setSearchParams(searchParams);
  }
  return (
    <>
      <li key={person.name} className={'relative'}>
        <div className="space-y-4">
          <div
            className="relative aspect-w-3 aspect-h-3 rounded-xl bg-cover bg-center bg-no-repeat cursor-pointer"
            onClick={handleOpen}
            style={{
              backgroundImage: `url(${person.avatar || AvatarTemplate})`,
            }}
          >
            {
              now.isSameOrAfter(person.holidayFrom) && now.isSameOrBefore(holidayTo) && (
                <div
                  className={'absolute fixed inset-0 bg-gray-700 bg-opacity-70 z-1 flex items-center justify-center rounded-xl'}
                >
                  <div>
                    <p className={'text-white text-xl text-center'}><br/>Отпуск
                      до {moment(person.holidayTo).format("DD.MM")}</p>
                  </div>


                </div>
              )
            }

            <div
              className={'absolute inset-0 z-2 flex items-end justify-end'}
              style={{gap: "-10px"}}
            >
              {
                (birthdayDate.format('MM-DD') === now.format('MM-DD')) && (

                  <div id={'birthday'}>
                  <img
                    className="aspect-4-3:h-8 aspect-5-4:h-10 aspect-16-10:h-16 aspect-16-9:h-12 hsm:h-8 hmd:h-12 hxl:h-14 h2xl:h-16"
                    src={`/services/${usefullIconsMap["birthday"].icon}`} alt={'birthday'}
                  />
                  </div>)
              }
              {
                (anniversaryDate.format('MM-DD') === now.format('MM-DD')) && (
                  <img
                    id={'anniversary'}
                    style={{marginLeft: "-14px"}}
                    className="aspect-4-3:h-8 aspect-5-4:h-10 aspect-16-10:h-16 aspect-16-9:h-12 hsm:h-8 hmd:h-12 hxl:h-14 h2xl:h-16"
                    src={`/services/${usefullIconsMap["anniversary"].icon}`}
                    alt={'birthday'}
                  />)
              }
            </div>
            <Tooltip anchorSelect={'anniversary'}>
              <p>
                Годовщина работы в артикс.
              </p>
            </Tooltip>
          </div>
          <div className="space-y-2">
            <div
              className="text-lg leading-6 font-medium space-y-1 cursor-pointer"
              style={{minHeight: "5rem"}}
              onClick={handleOpen}
            >
              <h3>
                {person.name} {person.surnameAccented ? person.surnameAccented : person.surname}
              </h3>
              <div className="has-tooltip relative flex flex-col">
                {/* {person.position?.description ? (
                  <div className="tooltip absolute -top-7 flex flex-col items-center mb-6">
                    <span
                      className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg display-linebreak"
                    >
                      {person.position.description}
                    </span>
                  </div>
                ) : null} */}
                <span className="text-gray-500">{person.position?.title}</span>
              </div>
            </div>
            {person.email && (
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faAt}
                  className="h-4 w-4 mr-2 text-red-600"
                />
                <a href={`mailto:${person.email}`}>{person.email}</a>
              </div>
            )}
            {person.phone && (
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="h-4 w-4 mr-2 text-red-600"
                />

                <a href={`tel://${person.phone.replace(/[^0-9.]/g, "")}`}>
                  {formatPhone(person.phone)}
                </a>
              </div>
            )}
            {person.telegram && (
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faTelegramPlane}
                  className="h-4 w-4 mr-2 text-red-600"
                />
                <a href={`tg://resolve?domain=${person.telegram}`}>
                  {formatTelegram(person.telegram)}
                </a>
              </div>
            )}
          </div>
        </div>
      </li>
      <Slideover title={`Карточка сотрудника`} open={open} setOpen={handleClose}>
        <PersonDetails
          key={person.id}
          person={person}
        />
      </Slideover>
    </>
  );
}
