export const vendorIconMap = {
  AppleSearchAds: { icon: "apple-search-ads.ico", title: "Apple Search Ads" },
  Calltouch: { icon: "calltouch.png", title: "Calltouch" },
  Comagic: { icon: "comagic.png", title: "Comagic" },
  Criteo: { icon: "criteo.png", title: "Criteo" },
  Facebook: { icon: "facebook.png", title: "Facebook" },
  Google: { icon: "google.ico", title: "Google" },
  K50: { icon: "k50.png", title: "K50" },
  MyTarget: { icon: "mytarget.svg", title: "MyTarget" },
  OWOX: { icon: "owox.png", title: "OWOX" },
  VK: { icon: "vk.png", title: "VK" },
  Yandex: { icon: "yandex.png", title: "Яндекс" },
  AppsFlyer: { icon: "appsflyer.png", title: "AppsFlyer" },
  Getintent: { icon: "getintent.png", title: "Getintent" },
  Hybrid : { icon: "hybrid.jpg", title: "Hybrid Platform" },
  SoftSkills: { icon: "artics.png", title: "Ключевые навыки руководителя" },
  "Яндекс.Медийная": { icon: "yandex.png", title: "Яндекс.Медийная" },
  "Яндекс. ПромоСтраницы": { icon: "yandex.png", title: "Яндекс. ПромоСтраницы" },
  "Яндекс. Медийная реклама в Директе": { icon: "yandex.png", title: "Яндекс.Медийная реклама в Директе" },
  "Яндекс: Реклама мобильных приложений": { icon: "yandex.png", title: "Яндекс.Мобильная реклама" },
};

export const vendorIconMapForMissingCertificates = {
  "Apple Search Ads": { icon: "apple-search-ads.ico", title: "Apple Search Ads" },
  "Calltouch ПО": { icon: "calltouch.png", title: "Calltouch ПО" },
  "Comagic ПО": { icon: "comagic.png", title: "Comagic ПО" },
  "Criteo": { icon: "criteo.png", title: "Criteo" },
  "Facebook": { icon: "facebook.png", title: "Facebook" },
  "К50": { icon: "k50.png", title: "К50" },
  "myTarget": { icon: "mytarget.svg", title: "myTarget" },
  "OWOX": { icon: "owox.png", title: "OWOX" },
  "VK.com": { icon: "vk.png", title: "VK.com" },
  "VK Ads (ВК Реклама)": { icon: "vk.png", title: "VK Ads (ВК Реклама)" },
  "AppsFlyer": { icon: "appsflyer.png", title: "AppsFlyer" },
  "Display & Video 360": { icon: "google.ico", title: "Display & Video 360" },
  "Google Analytics": { icon: "google.ico", title: "Google Analytics" },
  "DCM": { icon: "google.ico", title: "DCM" },
  "Getintent": { icon: "Getintent.png", title: "Getintent" },
  "Search ADS 360": { icon: "google.ico", title: "Search ADS 360" },
  "Google Tag Manager": { icon: "google.ico", title: "Google Tag Manager" },
  "Google Video": { icon: "google.ico", title: "Google Video" },
  "Google Display & Video": { icon: "google.ico", title: "Google Display & Video" },
  "Google Search": { icon: "google.ico", title: "Google Search" },
  "Google Apps": { icon: "google.ico", title: "Google Apps" },
  "Google Shopping": { icon: "google.ico", title: "Google Shopping" },
  "Google Efficiency": { icon: "google.ico", title: "Google Efficiency" },
  "Яндекс.Видео": { icon: "yandex.png", title: "Яндекс.Видео" },
  "Яндекс.Директ": { icon: "yandex.png", title: "Яндекс.Директ" },
  "Яндекс.Маркет": { icon: "yandex.png", title: "Яндекс.Маркет" },
  "Яндекс.Метрика": { icon: "yandex.png", title: "Яндекс.Метрика" },
  "Яндекс.Медийная": { icon: "yandex.png", title: "Яндекс.Медийная" },
  "Яндекс: Реклама мобильных приложений": { icon: "yandex.png", title: "Яндекс.Мобильная реклама" },
  "Яндекс. Медийная реклама в Директе": { icon: "yandex.png", title: "Яндекс.Медийная реклама в Директе" },
  "Яндекс. Геоперформанс": { icon: "yandex.png", title: "Яндекс. Геоперформанс"},
  "Яндекс. ПромоСтраницы": { icon: "yandex.png", title: "Яндекс. ПромоСтраницы" },
  "Hybrid (Self-Service)": { icon: "hybrid.jpg", title: "Hybrid Platform" },
  "Ключевые навыки руководителя": { icon: "artics.png", title: "Ключевые навыки руководителя" },
  "Наставничество, ИПР, Коучинг": { icon: "artics.png", title: "Наставничество, ИПР, Коучинг" },
  "Управление конфликтами": { icon: "artics.png", title: "Управление конфликтами" },
  "Навыки обратной связи": { icon: "artics.png", title: "Навыки обратной связи" },
  "Системное мышление, Приоритезация, Тайм-менеджмент": { icon: "artics.png", title: "Системное мышление, Приоритезация, Тайм-менеджмент" },
  "Подготовка и проведение презентаций": { icon: "artics.png", title: "Подготовка и проведение презентаций" },
  "Подготовка и проведение переговоров": { icon: "artics.png", title: "Подготовка и проведение переговоров" },
  "Абстрактное, стратегическое и тактическое мышление": { icon: "artics.png", title: "Абстрактное, стратегическое и тактическое мышление" },
  "Продажи": { icon: "artics.png", title: "Продажи" },
  "Стресс-менеджмент": { icon: "artics.png", title: "Стресс-менеджмент" },
  "Публичные выступления": { icon: "artics.png", title: "Публичные выступления" },
  "Управление собственным развитием": { icon: "artics.png", title: "Управление собственным развитием" },
  "Тайм-менеджмент и приоритизация": { icon: "artics.png", title: "Тайм-менеджмент и приоритизация" },
  "Ораторское мастерство для тендеров и отчетов": { icon: "artics.png", title: "Ораторское мастерство для тендеров и отчетов" },
};

export const usefullIconsMap = {
  VK: { icon: "../postsIcons/VK.svg", title: "VK" },
  Instagram: { icon: "../postsIcons/ig.svg", title: "Instagram" },
  Facebook: { icon: "../postsIcons/FB.svg", title: "Facebook" },
  Afishal: { icon: "../postsIcons/Afishal.png", title: "Afishal" },
  Techsupport: { icon: "../postsIcons/Techsupport.png", title: "Techsupport" },
  FleaMarket: { icon: "../postsIcons/FleaMarket.png", title: "FleaMarket" },
  MSKQA: { icon: "../postsIcons/msk.png", title: "MSKQA" },
  SPBQA: { icon: "../postsIcons/spb.png", title: "SPBQA" },
  Novators: { icon: "../postsIcons/Novators.png", title: "Novators"},
  ANews: { icon: "../postsIcons/ANews.png", title: "ANews" },
  BuroSmena: {icon: "../postsIcons/BuroSmena.png", title: "BuroSmena"},
  TrueDigital: { icon: "../postsIcons/TrueDigital.svg", title: "TrueDigital" },
  MSKQA_bottom: { icon: "../postsIcons/MSKQA_bottom.svg", title: "MSKQA_bottom" },
  SPBQA_bottom: { icon: "../postsIcons/SPBQA_bottom.svg", title: "SPBQA_bottom" },
  Tools_bottom: { icon: "../postsIcons/Tools_bottom.svg", title: "Tools_bottom" },
  Book_bottom: { icon: "../postsIcons/Book_bottom.svg", title: "Book_bottom" },
  Etiquette_bottom: { icon: "../postsIcons/Etiquette_bottom.svg", title: "Etiquette_bottom" },
  Naming_bottom: { icon: "../postsIcons/Naming_bottom.svg", title: "Naming_bottom" },
  123: { icon: "../postsIcons/123.svg", title: "123" },
  pw: { icon: "../postsIcons/pw.png", title: "pw" },
  pdfIcon: { icon: "../postsIcons/pdfIcon.png", title: "pdfIcon"},
  info: { icon: "../postsIcons/info.svg", title: "info"},
  birthday : { icon: "../postsIcons/birthday.svg", title: "birthday"},
  anniversary : { icon: "../postsIcons/anniversary.svg", title: "birthday"},
};

export const mainPageToolTypeMap = {
  SOCIAL: "Соц. сети",
  CHAT: "Чат",
  CHANNEL: "Канал",
};

export const postTypeMap = {
  MAIN_PAGE: "Стандартная",
  MAIN: "Баннер",
  PINNED: "Закрепленная",
};

export const buyingConditionContractStatusMap = {
  IN_PROGRESS: "В процессе подписания",
  NOT_SIGNED: "Не подписан",
  OTHER: "Другое",
  SIGNED: "Договор подписан",
  SIGNED_BY_MGCOM: "Договор подписан с MgCom",
};

export const legalnameTypeStatusMap = {
  JURIDICAL_PERSON: "Юр. лицо РФ", //JuridicalPerson Юр. лицо РФ
  INDIVIDUAL_ENTREPRENEUR: 'Индивидуальный предприниматель РФ', //IndividualEntrepreneur Индивидуальный предприниматель РФ
  PHYSICAL_PERSON: 'Физ. лицо РФ', //PhysicalPerson Физ. лицо РФ
  INTERNATIONAL_JURIDICAL_PERSON: 'Иностранное юр. лицо', //InternationalJuridicalPerson Иностранное юр. лицо
  INTERNATIONAL_PHYSICAL_PERSON:'Иностранное физ. лицо', //InternationalPhysicalPerson Иностранное физ. лицо
};

export const conditionStatusMap = {
  PREPAID: "Предоплата",
  POSTPAID: "Постоплата",
};

export const contractTypeStatusMap = {
  SERVICE_AGREEMENT: "Договор оказания услуг",
  MEDIATION_CONTRACT: "Посреднический договор",
};

export const contractSubjectTypeStatusMap = {
  DISTRIBUTION: "Договор на распространение рекламы", //Используется для типа договора "Договор оказания услуг".
  ORG_DISTRIBUTION: "Договор на организацию распространения рекламы", //Используется для типа договора "Договор оказания услуг".
  MEDIATION: "Посредничество", //Используется для типа договора "Посреднический договор".
  OTHER: "Иное",
};

export const contractActionTypeStatusMap = {
  CONTRACTING: "Заключение договоров", 
  DISTRIBUTION: "Действия в целях распространения рекламы", 
  COMMERCIAL_REPRESENTATION: "Коммерческое представительство", 
  OTHER: "Иное", 
};

export const actSidesRoleStatusMap = {
  RR: "Рекламораспространитель", 
  ORS: "Оператор рекламной системы", 
  RD: "Рекламодатель", 
  RA: "Рекламное агентство", 
};

export const userGradesMap = {
  BASE: "Основной",
  MEDIA: "Media",
  PROGRAMMATIC: "Programmatic",
  UPDATE: "Update",
  TARGET: "Таргет",
}
