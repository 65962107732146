import SearchPage from "pages/Team/SearchPage";
import AdminUsers from "pages/Admin/Users";
import AdminEmployeeDepartments from "pages/Admin/EmployeeDepartments";
import AdminEmployeePositions from "pages/Admin/EmployeePositions";
import AdminUsersEdit from "pages/Admin/Users/edit";
import AdminDepartmentsEdit from "pages/Admin/EmployeeDepartments/edit";
import AdminPositionsEdit from "pages/Admin/EmployeePositions/edit";
import AdminUsersCreate from "pages/Admin/Users/create";
import AdminPositionsCreate from "pages/Admin/EmployeePositions/create";
import AdminDepartmentCreate from "pages/Admin/EmployeeDepartments/create";
import Battle from "pages/Battle";
import Vote from "pages/Battle/Vote";
import CaseView from "pages/Battle/Case";
import PbtReport from "pages/Service/PbtReport";
import DepartmentPlan from "pages/Service/DepartmentPlan";
import CaseAdmin from "pages/Service/Case";
import PmReport from "pages/Service/PmReport";
import Certificates from "pages/Service/Certificates";
import AdminSellers from "pages/Admin/Sellers";
import AdminLegalnames from "pages/Admin/Legalnames";
import AdminClients from "pages/Admin/Clients";
import AdminBuyingFormats from "pages/Admin/BuyingFormats";
import AdminBuyingTypes from "pages/Admin/BuyingTypes";
import AdminVendors from "pages/Admin/Vendors";
import BuyingCondition from "pages/Service/BuyingCondition";
import BuyingCashbackRules from "pages/Service/BuyingCashbackRules";
import BuyingCashbackTransactions from "pages/Service/BuyingCashbackTransaction";
import RandomCoffee from "pages/RandomCoffee";
import VacationCalendar from "pages/Activities/VacationCalendar";
import ActivitiesCalendar from "pages/Activities/ActivitiesCalendar";
import Team from "pages/Team";
import News from "pages/Home";
import PostService from "pages/Home/components/PostService";
import ShowNews from "pages/Home/showNews";
import { UserRole } from "graphql/__generated-types__/globalTypes";
import AdminProjects from "pages/Admin/Projects";
import PbtCalculator from "pages/Service/PbtCalculator";
import FinalContracts from "pages/Admin/EPIP/Contracts/FinalContracts";
import Invoices from "pages/Admin/EPIP/Invoices";
import InitialContracts from "pages/Admin/EPIP/Contracts/InitialContracts";
import PbtCalculatorCreate from "pages/Service/PbtCalculator/create";
import PbtCalculatorEdit from "pages/Service/PbtCalculator/edit";
import DigitalCalendar from "pages/Activities/DigitalCalendar";
interface IRoute {
  [key: string]: {
    path: string;
    title?: string;
    component?: JSX.Element;
    link?: string;
    roles?: UserRole[];
    hidden?: boolean;
  };
}

export const mainMenuRoutes: IRoute = {
  news: {
    path: "/news",
    component: <News />,
    title: "Новости",
  },
  team: {
    path: "/team",
    component: <Team />,
    title: "Команда",
  },
  wiki: {
    path: "/wiki",
    component: null,
    link: "https://wiki.artics.ru",
    title: "Wiki",
  },
  corpculture: {
    path: "/corpculture",
    component: null,
    link: "http://articsculture.tilda.ws",
    title: "Корпоративная культура",
  },
  merchStore: {
    path: "/merchStore",
    component: null,
    link: "https://portal-new.artics.ru/shop/garment",
    title: "МерчМаг",
  },
  articsHub: {
    path: "/articsHub",
    component: null,
    link: "https://core.artics.ru/apps/65007849cd2b7843b6606a50/view",
    title: "Артикс Хаб",
  },
};

export const casePagesRoutes: IRoute = {
  vote: {
    path: "/activity/cases/:id/vote",
    component: <Vote />,
    title: "Голосование",
  },
  caseView: {
    path: "/activity/cases/:id",
    component: <CaseView />,
    title: "Просмотр кейса",
  },
};

export const commonRoutes: IRoute = {
  teamSearch: {
    path: "/team/search",
    component: <SearchPage />,
  },
};

export const activityRoutes: IRoute = {
  battleOfCases: {
    path: "/activity/battles",
    component: <Battle />,
    title: "Битва кейсов",
    hidden: true,
  },
  randomCoffee: {
    path: "/activity/random-coffee",
    component: <RandomCoffee />,
    title: "Random Coffee",
  },
  vacationCalendar: {
    path: "/activity/vacation-calendar",
    component: <VacationCalendar />,
    title: "Календарь отпусков",
  },
  activitiesCalendar: {
    path: "/activity/activities-calendar",
    component: <ActivitiesCalendar />,
    title: "Календарь активностей",
  },
  digitalCalendar: {
    path: "/activity/digital-calendar",
    component: <DigitalCalendar />,
    title: "Календарь digital-рынка",
  },
};

export const serviceMenuRoutes: IRoute = {
  pbts: {
    path: "/service/pbt123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/24954ce6",
    component: <PbtReport />,
    title: "Загруженность на проектах",
    roles: [UserRole.USER],
  },
  pbtCalculators: {
    path: "/service/calculator-pbt123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/1a917c3e",
    component: <PbtCalculator />,
    title: "Калькулятор маржи",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN, UserRole.BUYING_USER],
  },
  departmentPlans: {
    path: "/service/plans123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/fb0013b",
    component: <DepartmentPlan />,
    title: "Планы",
    roles: [UserRole.USER],
  },
  pmReports: {
    path: "/service/reports123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/900206e6",
    component: <PmReport />,
    title: "ПМ-отчеты",
    roles: [UserRole.USER],
  },
  cases: {
    path: "/service/cases123",
    component: <CaseAdmin />,
    title: "Битва кейсов",
    roles: [UserRole.BATTLE_CASE_ADMIN],
  },
  certificates: {
    path: "/service/certificates123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/58162a14",
    component: <Certificates />,
    title: "Сертификаты площадок",
    roles: [UserRole.USER],
  },
  buyingConditions: {
    path: "/service/buying-conditions123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/e7227603",
    component: <BuyingCondition />,
    title: "Баинговая таблица",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN, UserRole.BUYING_USER],
  },
  buyingCashbackTransactions: {
    path: "/service/buying-cashback123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/41b55543",
    component: <BuyingCashbackTransactions />,
    title: "Биллинговый кэшбек",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN, UserRole.BUYING_USER],
  },
};

export const cashbackRulesRoutes: IRoute = {
  buyingCashbackRules: {
    path: "/service/buying-cashback/rules123",
    component: <BuyingCashbackRules />,
    link: "https://core.artics.ru/apps/64a18beb359a726fab66aaf0/view",
    title: "Правила начисления кэшбека",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN, UserRole.BUYING_USER],
  },
};

export const adminMenuRoutes: IRoute = {
  users: {
    path: "/admin/users123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/f83b6d98",
    component: <AdminUsers />,
    title: "Сотрудники",
    roles: [UserRole.USER],
  },
  departments: {
    path: "/admin/departments123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/60a2d654",
    component: <AdminEmployeeDepartments />,
    title: "Отделы",
    roles: [UserRole.USER],
  },
  positions: {
    path: "/admin/positions123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/7f5cbcd1",
    component: <AdminEmployeePositions />,
    title: "Должности",
    roles: [UserRole.USER],
  },
  sellers: {
    path: "/admin/sellers123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/1c477fc1",
    component: <AdminSellers />,
    title: "Селлеры",
    roles: [UserRole.USER],
  },
  legalnames: {
    path: "/admin/legalnames123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/968b6f91",
    component: <AdminLegalnames />,
    title: "Юридические лица",
    roles: [UserRole.USER],
  },
  clients: {
    path: "/admin/clients123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/db33fd8a",
    component: <AdminClients />,
    title: "Клиенты",
    roles: [UserRole.USER],
  },
  clientProjects: {
    path: "/admin/clientProjects123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/325c882e",
    component: <AdminProjects />,
    title: "Проекты",
    roles: [UserRole.USER],
  },
  buyingFormats: {
    path: "/admin/buying-formats123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/7c927e1c",
    component: <AdminBuyingFormats />,
    title: "Форматы закупки",
    roles: [UserRole.USER],
  },
  buyingTypes: {
    path: "/admin/buying-types123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/dd90eb2c",
    component: <AdminBuyingTypes />,
    title: "Типы закупки",
    roles: [UserRole.USER],
  },
  vendors: {
    path: "/admin/vendors123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/2d4b29b8",
    component: <AdminVendors />,
    title: "Площадки",
    roles: [UserRole.USER],
  },
  final_contracts: {
    path: "/contracts/final123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/d892c8e",
    component: <FinalContracts />,
    title: "Конечные договоры",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN],
  },
  initial_contracts: {
    path: "/contracts/initial123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/d892c8e",
    component: <InitialContracts />,
    title: "Изначальные договоры",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN],
  },
  invoices: {
    path: "/invoices123",
    link: "https://core.artics.ru/apps/64107e7762be482a5df58194/view/a5aa781c",
    component: <Invoices />,
    title: "Акты",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN],
  },
};

export const pbtCalculatorPagesRoutes: IRoute = {
  pbtCalculatorCreate: {
    path: "/service/calculator-pbt/new",
    component: <PbtCalculatorCreate />,
    title: "Создание калькулятора маржи",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN, UserRole.BUYING_USER],
  },
  pbtCalculatorEdit: {
    path: "/service/calculator-pbt/:id/edit",
    component: <PbtCalculatorEdit />,
    title: "Редактирование калькулятора маржи",
    roles: [UserRole.BUYING_ADMIN, UserRole.GLOBAL_ADMIN, UserRole.BUYING_USER],
  },
};

export const adminPagesRoutes: IRoute = {
  userEdit: {
    path: "/admin/users/:id/edit",
    component: <AdminUsersEdit />,
    title: "Редактирование сотрудника",
    roles: [UserRole.GLOBAL_ADMIN, UserRole.HR_ADMIN],
  },
  userCreate: {
    path: "/admin/users/new",
    component: <AdminUsersCreate />,
    title: "Создание нового сотрудника",
    roles: [UserRole.GLOBAL_ADMIN, UserRole.HR_ADMIN],
  },
  positionEdit: {
    path: "/admin/positions/:id/edit",
    component: <AdminPositionsEdit />,
    title: "Редактирование должности",
    roles: [UserRole.GLOBAL_ADMIN, UserRole.HR_ADMIN],
  },
  positionCreate: {
    path: "/admin/positions/new",
    component: <AdminPositionsCreate />,
    title: "Создание должности",
    roles: [UserRole.GLOBAL_ADMIN, UserRole.HR_ADMIN],
  },
  departmentEdit: {
    path: "/admin/departments/:id/edit",
    component: <AdminDepartmentsEdit />,
    title: "Редактирование отдела",
    roles: [UserRole.GLOBAL_ADMIN, UserRole.HR_ADMIN],
  },
  departmentsCreate: {
    path: "/admin/departments/new",
    component: <AdminDepartmentCreate />,
    title: "Создание отдела",
    roles: [UserRole.GLOBAL_ADMIN, UserRole.HR_ADMIN],
  },
};

export const newsMenuRoutes: IRoute = {
  showPost: {
    path: "/news/:id",
    component: <ShowNews />,
    title: "Новость :id",
  },
};

export const newsMenuServicesRoutes: IRoute = {
  postService: {
    path: "/postsTable",
    component: <PostService />,
    title: "Новости главной страницы",
    roles: [UserRole.PR_ADMIN, UserRole.GLOBAL_ADMIN],
  },
};
